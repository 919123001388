// Hero

#hero {
  text-align: center;
  margin-bottom: 4rem;
  padding: 0;

  img {
    margin: 2rem 0; }

  #mission {
    font-family: $font-stack-serif;
    font-style: italic;
    text-align: center;
    border-bottom: 2px solid $lightblue;
    border-top: 2px solid $lightblue;
    color: $navy;
    margin: 0 1rem 2rem;
    padding: 1rem;

    span {
      color: $red;
      font-weight: 700; } } }

@media (min-width: $screen-sm) {
  #hero {
    .responsive-flex {
      justify-content: center;
      align-items: center; }

    img {
      width: 80%; }

    #mission {
      width: 70%;
      margin: 0 auto 2rem;
      font-size: 1.5rem; } } }
