// Sanitize reboot
// Adapted from https://jonathantneal.github.io/sanitize.css/
// Some sanitization not needed, already done by Twitter Reboot.css

* {
  background-repeat: no-repeat; }

body {
	margin: 0; }

img {
  max-width: 100%; }

nav ol, nav ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0; }
