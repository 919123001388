// Typography

$font-stack-sans: "Oswald", "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-stack-serif: Georgia, Times, "Times New Roman", serif;

body {
  font-family: $font-stack-sans;
  font-size: 100%;
  font-weight: 300; }

h1, h2, h3 {
  font-weight: 400; }

h1 {
  font-size: 1.75rem; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1rem; }

@media (min-width: $screen-sm) {
  h1 {
    font-size: 2rem; }

  h2 {
    font-size: 1.6rem; }

  h3 {
    font-size: 1rem; } }
