// Navigation
#nav-section {
  padding: 0; }

nav {
  color: white;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ul {
    display: none;
    width: 100%;
    margin: 2rem 0 0 0;
    list-style: none;
    text-align: center;
    text-transform: uppercase; }

  li {
    margin-bottom: 1rem; }

  li a {
    color: white;
    padding: 0.5rem; }

  a:hover {
    color: $lightblue;
    text-decoration: none;
    background-color: $red; } }

#navbrand {
  font-family: "Droid Serif", $font-stack-serif;
  font-size: 1.25rem;

  i {
    color: $red;
    margin-left: 1rem;
    font-size: 0.8rem; }

  a {
    text-decoration: none;
    color: inherit; }

  a:hover {
    color: inherit;
    background-color: inherit; } }

@media (min-width: $screen-sm) {

  #hamburger {
    display: none; }

  nav ul {
    width: auto;
    display: block;
    padding-top: 0;
    margin-top: 0; }

  nav ul li {
    display: inline;
    margin-left: 1rem; } }
