// Footer
footer {
  padding: 2rem;
  color: white;
  text-align: center;

  .sociallinks a {
    color: white; }

  .footer-address {
    font-size: 0.75rem; } }
