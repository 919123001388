// Homepage

h1.home-header {
  max-width: $max-width;
  margin: 0 auto 2rem auto;
  text-align: center;
  text-transform: uppercase;
  color: $red;
  font-size: 1.8rem; }

.home-section {
  margin-bottom: 2rem;
  padding: 0 0 2rem 0;
  text-align: center;

  h1, h2, h3, h4 {
    font-family: $font-stack-sans;
    margin-bottom: 1rem; }

  h2 {
    //text-transform: uppercase
    background-color: white;
    color: $navy;
    padding: 0.5rem;
    font-size: 2rem; }

  h3 {
    background-color: $navy;
    color: white;
    font-weight: 300;
    font-size: 1.3rem;
    padding: 0.5rem; }

  p {
    text-align: left;
    margin-bottom: 2rem;
    padding: 0 2rem; } }

.ca-image {
  width: 100%;
  margin-bottom: 0rem;
  align-self: center; }

.ca-headers {
  padding: 2rem; }

.ca-content {
  font-family: $font-stack-serif; }

.pa-detail {
  padding: 0;
  text-align: left;
  font-size: 1.25rem;
  margin: 1rem;

  h1 {
    font-size: 1.25rem;
    color: $red; }

  p {
    padding: 0;
    font-size: 1rem; }

  img {
    margin-bottom: 1rem; } }

.videocontainer {
  position: relative;
  padding-bottom: 60%;

  height: 0;
  overflow: hidden;
  margin-bottom: 1rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

.commentary {
  padding: 2rem;

  .commentary-teaser {
    margin-bottom: 2rem; }

  h1 {
    text-align: left; }

  p {
    padding: 0;
    font-family: $font-stack-serif; }

  a {
    display: block;
    padding: 1rem;
    background-color: $red;
    color: white;
    text-transform: uppercase; } }



@media (min-width: $screen-sm) {
  h1.home-header {
    font-size: 2.5rem; }

  .ca-image {
    width: 50%;
    align-self: flex-start;
    margin-top: 2rem; }

  .ca-headers {
    width: 50%; }

  .ca-content {
    width: 80%;
    margin: 1rem auto;
    font-size: 1.25rem; }

  .pa-detail {
    width: 30%;
    margin: 0 1rem 1rem 0;

    &:last-of-type {
      margin-right: 0; } }

  .commentary-teaser {
    width: 50%;
    text-align: left;

    a {
      display: inline-block; } } }
