// Social

ul.sociallinks {
  list-style: none;
  padding: 0 1rem;
  font-size: 1.75rem;

  li {
    display: inline;
    padding: 1rem; }

  a {
    color: $navy; }

  a:hover {
    color: $red; } }

@media (min-width: $screen-sm) {
  ul.sociallinks {
    font-size: 2.75rem; } }
