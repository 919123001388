// Mailchimp
#mc_embed_signup {
  padding: 2rem;
  text-align: center;
  color: $navy;

  label {
    font-weight: 400; }

  .button {
    font-size: 0.75rem;
    background-color: $red;
    text-transform: uppercase; } }

footer #mc_embed_signup {
  color: white;

  .button {
    background-color: $red; } }
