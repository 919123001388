// Colors

$lightblue: rgb(229, 239, 255);
$blue: rgb(48, 129, 180);
$navy: rgb(29, 41, 53);
$red: rgb(204,51,51);

.navy {
  background-color: $navy;
  color: white; }

.red {
  background-color: $red;
  color: white;
  a {
    color: $navy; } }

.lightblue {
  background-color: $lightblue; }
