// Base
@import "_reboot";
@import "_sanitize";

// Variables
@import "_mediaqueries";
@import "_colors";
@import "_typography";

// Components
@import "_buttons";
@import "_nav";
@import "_hero";
@import "_social";
@import "_mailchimp";
@import "_footer";

// Pages
@import "_homepage";
@import "_article";

// Utilities

b {
  font-weight: bold; }

.container {
  max-width: $max-width;
  margin: 0 auto; }

.responsive-flex {
  display: block; }

.show {
  display: block; }

// Dev Styles


// Responsive
@media (min-width: $max-width) {
  .responsive-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }
