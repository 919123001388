// Article

article {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  font-family: $font-stack-serif;

  h1 {
    font-family: $font-stack-sans;
    color: $red;
    margin-bottom: 2rem; }

  .meta {
    margin-bottom: 2rem; }

  .date {
    background-color: $lightblue;
    color: $navy;
    padding: 0.5rem;
    margin-bottom: 4rem;
    font-family: $font-stack-sans;
    font-weight: 400; } }

@media (min-width: $max-width) {

  article h1 {
    font-size: 3rem; } }
